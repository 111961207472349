<template>
  <view-item title="收款">
    <template #operation> </template>

    <template #manifest> </template>

    <view-item-content> </view-item-content>
  </view-item>
</template>

<script lang="ts">
export default factory({
  components: {},

  config: {
    children: {}
  }
})
</script>
